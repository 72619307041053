import React from "react";
import "bootstrap/dist/css/bootstrap.min.css";
import "../keyactivation/keyactivation.component.css";
// import CatDog from "../../assets/keyicon.png";
import "font-awesome/css/font-awesome.min.css";
import $ from "jquery";
import axios from "axios";
import {
  TextField,
  Select,
  MenuItem,
  Button,
  Snackbar,
  IconButton,
} from "@material-ui/core";
import Navbar from "../header/header.component.jsx";
import vehiclekey from "../../assets/vehiclekey.png";
import otherkey from "../../assets/otherkey.png";
import QrCodeScannerIcon from "@mui/icons-material/QrCodeScanner";
import * as BsIcons from "react-icons/bs";
import * as AiIcons from "react-icons/ai";
import * as MdIcons from "react-icons/md";
import { switchUrls } from "../../api/index.js";

const baseURL = switchUrls("key");
function readCookie(name) {
  var nameEQ = name + "=";
  var ca = document.cookie.split(";");

  for (var i = 0; i < ca.length; i++) {
    var c = ca[i];

    while (c.charAt(0) == " ") c = c.substring(1, c.length);

    if (c.indexOf(nameEQ) == 0) return c.substring(nameEQ.length, c.length);
  }

  return null;
}

let usertoken = readCookie("token");
// const baseURL = "http://localhost:3050";

export default class KeyActivation extends React.Component {
  constructor(props) {
    super(props);

    this.state = {
      imageUrl: null,
      selectedImage: null,
      tag_number: this.props.match.params.tag_number,
      key_imagelocation: null,
      key_imagename: null,
      imageUrlvector: null,
      tag_status: "active",
      valuable_type: "CarorBikeKeys",
      valuable_id: null,
      valuableTypeToIdMap: {
        CarorBikeKeys: 1,
        OtherKeys: 2,
      },
      valuableImages: {
        1: "vehiclekey.png",
        2: "otherkey.png",
      },
      imageUrlvector:
        "https://storage.googleapis.com/pettag/qr/assets/vehiclekey.png",
      vehicle_brand: "",
      vehicle_otherkeys: "",
      vehicle_licensenumber: "",
      keychain_description: "",
      other_noofkeys: "",
      chasisnumber: "",
      drivinglicense: "",
      snackbaropen: false,
      snackbarmsg: "",
      message: "",
      sidebar: true,
      subMenu: true,
    };

    this.handleChange = this.handleChange.bind(this);
    this.handleDropdownChange = this.handleDropdownChange.bind(this);
    this.onImgChange = this.onImgChange.bind(this);
    this.showSidebar = this.showSidebar.bind(this);
    this.showSubMenu = this.showSubMenu.bind(this);
  }

  componentDidMount() {
    $("#profile-image-uploadActi").on("click", function () {
      $("#choose-profile-imageActi").trigger("click");
    });

    $("#profile-image-uploadActi-1").on("click", function () {
      $("#choose-profile-imageActi").trigger("click");
    });
  }
  onImgChange(event) {
    this.setState({ imagesArray: "", file: [null] });
    this.fileObj = [];
    this.fileArray = [];
    this.setState({
      imagesArray: [...this.state.imagesArray, ...event.target.files],
    });
    this.fileObj.push(event.target.files);

    for (let i = 0; i < this.fileObj[0].length; i++) {
      this.fileArray.push(URL.createObjectURL(this.fileObj[0][i]));
    }

    this.setState({ file: this.fileArray });
  }
  handleDropdownChange(event) {
    const selectedValuableType = event.target.value;
    const selectedId = this.state.valuableTypeToIdMap[selectedValuableType];
    this.setState({
      valuable_type: selectedValuableType,
      valuable_id: selectedId,
      imageUrl: this.state.valuableImages[selectedId],
      key_imagelocation: null,
      imageUrl: null,
    });
  }

  // handleChange(event) {
  //   if (event.target.files[0]) {
  //     this.setState({ message: "" });
  //     this.setState({
  //       selectedImage: event.target.files[0],
  //       imageUrl: URL.createObjectURL(event.target.files[0]),
  //     });
  //   }
  // }
  handleChange(event) {
    // if (event.target.files[0]) {
    //   var sizeinmb = event.target.files[0].size / (1024 * 1024);
    //   console.log(sizeinmb);
    //   if (sizeinmb > 2) {
    //     this.setState({ message: "File size should be less than 2 MB" });
    //   } else {
    //     this.setState({ message: "" });
    //     const selectedImage = event.target.files[0];
    //     console.log(selectedImage, "image");
    //     this.setState({
    //       selectedImage: event.target.files[0],
    //       imageUrl: URL.createObjectURL(event.target.files[0]),
    //       key_imagelocation: null,
    //     });
    //   }
    // }
  }
  deleteimage = (e) => {
    this.setState({
      snackbaropen: true,
      snackbarmsg: "Feature coming soon....!",
    });
    // axios
    //   .post(`${baseURL}/api/key/deletekeyimage`, this.state)
    //   .then((response) => {
    //     console.log(response);
    //     if (response.data === "Key Image removed") {
    //       this.setState({
    //         imageUrl:
    //           "https://storage.googleapis.com/pettag/qr/assets/vehiclekey.png",
    //         bag_imagelocation: null,
    //         bag_imagename: null,
    //       });
    //     } else if (response.data === "wrong data") {
    //       this.setState({
    //         snackbaropen: true,
    //         snackbarmsg: "Image could not be deleted",
    //       });
    //     }
    //   })
    //   .catch((error) => {
    //     this.setState({ snackbaropen: true, snackbarmsg: "Unsuccessful" });
    //   });
  };
  changeHandler = (e) => {
    this.setState({ [e.target.name]: e.target.value });
  };

  snackbarClose = (event) => {
    this.setState({ snackbaropen: false });
  };

  submitHandler = (e) => {
    e.preventDefault();
    const {
      tag_status,
      valuable_type,
      imageUrl,
      vehicle_licensenumber,
      keychain_description,
      key_imagelocation,
      other_noofkeys,
      chasisnumber,
      drivinglicense,
      vehicle_brand,
      vehicle_otherkeys,
      imageUrlvector,
      valuable_id,
    } = this.state;
    const data = {
      tag_number: this.props.match.params.tag_number,
      tag_status,
      valuable_type,
      imageUrl,
      vehicle_licensenumber,
      keychain_description,
      other_noofkeys,
      chasisnumber,
      drivinglicense,
      vehicle_brand,
      vehicle_otherkeys,
      key_imagelocation,
      imageUrlvector,
      valuable_id,
    };
    axios
      .post(`${baseURL}/api/key/keyactivation`, data, {
        headers: { Authorization: usertoken },
      })
      .then((response) => {
        if (response.data === "Tag Already Activated") {
          this.setState({ snackbaropen: true, snackbarmsg: response.data });
        } else if (response.data === "Tag not Found by the specified Number") {
          this.setState({ snackbaropen: true, snackbarmsg: response.data });
        } else if (response.data === "Key Activated Successfully") {
          window.location.href = "/dashboard/" + localStorage.getItem("uid");

          const fd = new FormData();

          fd.append("image", this.state.selectedImage);
          fd.append("tag_number", this.props.match.params.tag_number);

          axios
            .post(`${baseURL}/api/key/keyimageupload`, fd)
            .then((res) => {
              this.setState({
                snackbaropen: true,
                snackbarmsg: "Key Activated Successfully",
              });
            })
            .catch((error) => {
              console.log(error);
            });
        }
      })
      .catch((error) => {
        console.log(error);
      });
  };

  showSidebar(e) {
    e.preventDefault();

    this.setState((currentState) => ({
      sidebar: !currentState.sidebar,
    }));
  }

  showSubMenu(e) {
    e.preventDefault();

    this.setState((currentState) => ({
      subMenu: !currentState.subMenu,
    }));
  }

  render() {
    const {
      imageUrl,
      tag_number,
      bag_imagelocation,
      chasisnumber,
      drivinglicense,
      valuable_type,
      vehicle_brand,
      vehicle_licensenumber,
      keychain_description,
      other_noofkeys,
      valuableTypeToIdMap,
      valuableImages,
      sidebar,
      subMenu,
    } = this.state;
    const selectedId = valuableTypeToIdMap[valuable_type];
    const selectedImageFilename = valuableImages[selectedId];
    const imageUrlvectors = `https://storage.googleapis.com/pettag/qr/assets/${selectedImageFilename}`;
    console.log(bag_imagelocation, "locationiamage");
    console.log(imageUrl, "purchase date");
    console.log(imageUrlvectors, "vecto");
    return (
      <>
        <Navbar />
        <div className="keyactivation-main-wrapper">
          <div className="keyactivation-left-wrapper">
            <div className="keyactivation-sidebar-toggle-btn-wrapper">
              <button
                className="keyactivation-sidebar-toggle-btn"
                onClick={this.showSidebar}
              >
                {sidebar ? (
                  <BsIcons.BsLayoutSidebarInset />
                ) : (
                  <BsIcons.BsLayoutSidebarInsetReverse />
                )}
              </button>
            </div>

            <div
              className={
                sidebar
                  ? "keyactivation-sidebar-container-hide"
                  : "keyactivation-sidebar-container"
              }
            >
              <button
                className="keyactivation-sidebar-menu-link"
                onClick={this.showSubMenu}
              >
                MY TAGS{" "}
                {subMenu ? (
                  <AiIcons.AiFillCaretDown />
                ) : (
                  <AiIcons.AiFillCaretUp />
                )}
              </button>

              <ul
                className={
                  subMenu
                    ? "keyactivation-sidebar-menu-ul-hide"
                    : "keyactivation-sidebar-menu-ul"
                }
              >
                <li>
                  <button
                    className="keyactivation-sidebar-menu-ul-links"
                    onClick={() => {
                      window.location.href =
                        "/dashboard/" + this.props.match.params.tag_number;
                    }}
                  >
                    ALL TAGS
                  </button>
                </li>

                <li>
                  <button
                    className="keyactivation-sidebar-menu-ul-links"
                    onClick={() => {
                      window.location.href =
                        "/dashboard/" + this.props.match.params.tag_number;
                    }}
                  >
                    ACTIVE | INACTIVE TAGS
                  </button>
                </li>

                <li>
                  <button
                    className="keyactivation-sidebar-menu-ul-links"
                    onClick={() => {
                      window.location.href =
                        "/dashboard/" + this.props.match.params.tag_number;
                    }}
                  >
                    LOST | FOUND TAGS
                  </button>
                </li>
              </ul>

              <button
                className="keyactivation-sidebar-menu-link"
                onClick={() => {
                  window.location.href =
                    "/dashboard/" + this.props.match.params.tag_number;
                }}
              >
                ACTIVATE TAG
              </button>

              <button
                className="keyactivation-sidebar-menu-link"
                onClick={() => {
                  window.location.href =
                    "/scan-notification/" + this.props.match.params.tag_number;
                }}
              >
                NOTIFICATIONS
              </button>
            </div>
          </div>
        </div>
        <div class="key-container">
          <div className="background-image">
            <div className="key-activation-container">
              <p>
                <strong>Key Activation</strong>
              </p>
              <div className="key-activation-form-container">
                <form className="key-activation-form">
                  {/* <div className="bag-image-upload">
                    <img alt="" src={imageUrl} className="bag-image" />
                    <p style={{ textAlign: "center" }}>
                      <QrCodeScannerIcon />
                      <b>
                        {tag_number && tag_number !== "-" ? tag_number : "N/A"}
                      </b>
                    </p>
                  </div> */}
                  <div className="editPetDetails-user-img">
                    {imageUrl ? (
                      <img
                        alt="profile"
                        src={imageUrl}
                        className="editPetDetails-image"
                      />
                    ) : (
                      <img
                        alt="profile"
                        src={imageUrlvectors}
                        className="editPetDetails-image"
                      />
                    )}
                    {/* {!bag_imagelocation && !imageUrlvector ? (
                      <img
                        alt="profile"
                        src={imageUrlvector}
                        // src={
                        //   "https://storage.googleapis.com/pettag/qrtagdev/assets/catdog1.jpg"
                        // }
                        className="editPetDetails-image "
                      />
                    ) : (
                      ""
                    )} */}
                    {/* <div className="editPetDetails-edit-btn-wrap">
                      <label
                        // htmlFor="upload"
                        className="editPetDetails-edit-button-image"
                        style={{ cursor: "pointer" }}
                        onClick={() => {
                          this.setState({
                            snackbaropen: true,
                            snackbarmsg: "Feature coming soon....!",
                          });
                        }}
                      >
                        <MdIcons.MdEdit /> Change
                      </label>

                      <input
                        id="upload"
                        className="editPetDetails-input-user-img"
                        type="file"
                        onChange={this.handleChange}
                      />
                      <button
                        className="editPetDetails-delete-button-image "
                        onClick={this.deleteimage}
                      >
                        <AiIcons.AiFillDelete /> Remove
                      </button>
                    </div> */}
                    <div
                      className=" editPetDetails-user-qr mt-3 d-flex align-items-center justify-content-center"
                      style={{ gap: "6px" }}
                    >
                      <img
                        src="https://storage.googleapis.com/pettag/qr/assets/qrcode.png"
                        style={{ width: "30px", height: "30px" }}
                      />
                      <p style={{ marginTop: "15px" }}>
                        <b>
                          {tag_number && tag_number !== "-"
                            ? tag_number
                            : "N/A"}
                        </b>
                      </p>
                    </div>{" "}
                  </div>
                  <div>
                    <div className="keyactivation-right-form-label-input-container">
                      {/* <label>Select Valuable Type *</label> */}
                      <Select
                        select
                        // className="keyactivation-right-form-select"
                        value={valuable_type}
                        label="Valuable Type"
                        required
                        variant="outlined"
                        onChange={this.handleDropdownChange}
                      >
                        <MenuItem value="">Please select</MenuItem>
                        <MenuItem value="CarorBikeKeys">Vehicle Key</MenuItem>
                        <MenuItem value="OtherKeys">Other Key</MenuItem>
                      </Select>
                      {(valuable_type === "Other Keys" ||
                        valuable_type === "OtherKeys") && (
                        <>
                          <TextField
                            label="Keychain Description"
                            className="keydetails-right-form-inputs"
                            name="keychain_description"
                            variant="outlined"
                            value={keychain_description}
                            onChange={this.changeHandler}
                          />
                          <TextField
                            label="No of Keys"
                            className="keydetails-right-form-inputs"
                            maxLength="20"
                            variant="outlined"
                            name="other_noofkeys"
                            value={other_noofkeys}
                            onChange={this.changeHandler}
                          />
                        </>
                      )}
                    </div>

                    <br />
                    {(valuable_type === "Car or Bike Keys" ||
                      valuable_type === "CarorBikeKeys") && (
                      <>
                        <div className="keydetails-right-form-label-input-container">
                          <div className="two-by-two-fields">
                            <div className="two-by-two-field">
                              <TextField
                                label="Keychain Description"
                                className="keydetails-right-form-inputs"
                                name="keychain_description"
                                variant="outlined"
                                value={keychain_description}
                                onChange={this.changeHandler}
                              />
                            </div>
                            <div className="two-by-two-field">
                              <TextField
                                label="No of Keys"
                                className="keydetails-right-form-inputs"
                                maxLength="20"
                                variant="outlined"
                                name="other_noofkeys"
                                value={other_noofkeys}
                                onChange={this.changeHandler}
                              />
                            </div>
                          </div>
                          <div className="two-by-two-fields">
                            <div className="two-by-two-field">
                              <TextField
                                label="Vehicle License Plate Number"
                                className="keydetails-right-form-inputs"
                                name="vehicle_licensenumber"
                                variant="outlined"
                                value={vehicle_licensenumber}
                                onChange={this.changeHandler}
                              />
                            </div>
                            <div className="two-by-two-field">
                              <TextField
                                label="Vehicle Brand & Model"
                                className="keydetails-right-form-inputs"
                                name="vehicle_brand"
                                variant="outlined"
                                value={vehicle_brand}
                                onChange={this.changeHandler}
                              />
                            </div>
                          </div>
                          <div className="two-by-two-fields">
                            <div className="two-by-two-field">
                              <TextField
                                label="Chasis Number"
                                className="keydetails-right-form-inputs"
                                name="chasisnumber"
                                variant="outlined"
                                value={chasisnumber}
                                onChange={this.changeHandler}
                              />
                            </div>
                            <div className="two-by-two-field">
                              <TextField
                                label="Driving License Number"
                                className="keydetails-right-form-inputs"
                                name="drivinglicense"
                                variant="outlined"
                                value={drivinglicense}
                                onChange={this.changeHandler}
                              />
                            </div>
                          </div>
                        </div>
                      </>
                    )}
                    <div className="keyactivation-right-form-btn-container">
                      <Button
                        variant="contained"
                        color="primary"
                        className="keyactivation-right-form-btn"
                        style={{ backgroundColor: "#ffc107" }}
                        onClick={this.submitHandler}
                      >
                        SUBMIT
                      </Button>

                      <Button
                        variant="contained"
                        color="default"
                        className="keyactivation-right-form-btn"
                        style={{ backgroundColor: "white" }}
                        onClick={() => {
                          window.location.href =
                            "/dashboard/" + localStorage.getItem("uid");
                        }}
                      >
                        CANCEL
                      </Button>
                    </div>

                    <Snackbar
                      open={this.state.snackbaropen}
                      autoHideDuration={3000}
                      onClose={this.snackbarClose}
                      message={this.state.snackbarmsg}
                      action={[
                        <IconButton
                          key="close"
                          aria-label="Close"
                          color="inherit"
                          onClick={this.snackbarClose}
                        >
                          X
                        </IconButton>,
                      ]}
                    />
                  </div>
                </form>
              </div>
            </div>
          </div>
        </div>
      </>
    );
  }
}
