import { useState, useCallback, useEffect } from "react";
import { GoogleMap, Marker, useJsApiLoader } from "@react-google-maps/api";

const containerStyle = {
  width: "400px",
  height: "400px",
};
function readCookie(name) {
  var nameEQ = name + "=";
  var ca = document.cookie.split(";");

  for (var i = 0; i < ca.length; i++) {
    var c = ca[i];

    while (c.charAt(0) == " ") c = c.substring(1, c.length);

    if (c.indexOf(nameEQ) == 0) return c.substring(nameEQ.length, c.length);
  }

  return null;
}
let usertoken = readCookie("token");
const Name = localStorage.getItem("name");
let token = usertoken;
// let tag_number = props;
// console.log(tag_number);
// const queryParams = new URLSearchParams(window.location.search);
// console.log(queryParams);
// const code = queryParams.get("code");
// const baseURL = "http://localhost:3020";
// const center = {
//   lat: 19.002157,
//   lng: 72.841576,
// };
const position = {
  lat: 19.002157,
  lng: 72.841576,
};
function TestMap(props) {
  // console.log("props map", props);

  const { isLoaded } = useJsApiLoader({
    id: "google-map-script",
    googleMapsApiKey: "AIzaSyAAwak05wJ9r_l4XUfs468Zu1CmF-It0y8",
  });

  const [map, setMap] = useState(null);
  const [marker, setMarkers] = useState([]);
  const [latitude, setLatitude] = useState("");
  const [longitude, setLongitude] = useState(0);
  const [success, setSuccess] = useState(true);
  const [currPos, setCurrPos] = useState({ currPosLat: 0, currPosLong: 0 });
  const [lostPos, setLostPos] = useState({ lostPosLat: 0, lostPosLong: 0 });

  const clickPosition = (event) => {
    setLatitude(marker[0].lat);
    setLongitude(marker[0].lng);
    const location = {
      lat: marker[0].lat,
      lng: marker[0].lng,
    };
    props.sendlatitude(location);
  };

  function getCurrentPosition() {
    navigator.geolocation.getCurrentPosition((position) => {
      setCurrPos({
        currPosLat: position.coords.latitude,
        currPosLong: position.coords.longitude,
      });
    });
  }

  function getLostlocation() {
    navigator.geolocation.getCurrentPosition(() => {
      setLostPos({
        lostPosLat: +props.lostlatitude,
        lostPosLong: +props.lostlongitude,
      });
    });
  }
  console.log(lostPos);

  var center = {
    lat: currPos.currPosLat,
    lng: currPos.currPosLong,
  };
  var centerlatitude = {
    lat: lostPos.lostPosLat,
    lng: lostPos.lostPosLong,
  };
  const onLoad = useCallback(function callback(map) {
    const bounds = new window.google.maps.LatLngBounds(center);

    map.fitBounds(bounds);

    setMap(map);
  }, []);

  const onUnmount = useCallback(function callback(map) {
    setMap(null);
  }, []);

  return isLoaded ? (
    <>
      <GoogleMap
        mapContainerStyle={containerStyle}
        center={props.editlocation == false ? centerlatitude : center}
        zoom={18}
        onLoad={
          props.editlocation == false ? getLostlocation : getCurrentPosition
        }
        onClick={(event) => {
          setMarkers(() => [
            {
              lat: event.latLng.lat(),
              lng: event.latLng.lng(),
              time: new Date(),
            },
          ]);
          setLatitude(event.latLng.lat());
          setLongitude(event.latLng.lng());
        }}
        onUnmount={onUnmount}
      >
        {marker.map((marker) => (
          <Marker
            key={marker.time}
            draggable={true}
            position={{ lat: marker.lat, lng: marker.lng }}
            onLoad={clickPosition}
          />
        ))}
      </GoogleMap>
    </>
  ) : (
    <></>
  );
}

export default TestMap;
