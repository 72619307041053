import React from "react";
import "bootstrap/dist/css/bootstrap.min.css";
import "../keyreportlost/reportlost.component.css";
import "font-awesome/css/font-awesome.min.css";
// import Button from "@material-ui/core/Button";
// import ToggleButton from "@material-ui/lab/ToggleButton";
// import ToggleButtonGroup from "@material-ui/lab/ToggleButtonGroup";
import { DatePicker } from "@mui/x-date-pickers/DatePicker";
import { TimePicker } from "@mui/x-date-pickers/TimePicker";
import axios from "axios";
import Snackbar from "@material-ui/core/Snackbar";
import IconButton from "@material-ui/core/IconButton";
import MapContainer from "./mapcontainer";
import Navbar from "../header/header.component.jsx";
import "date-fns";
// import Userimage from "../../assets/user2.png";
// import { Link } from "react-router-dom";
import {
  Stepper,
  Step,
  StepLabel,
  Button,
  TextField,
  FormControl,
  InputLabel,
  Select,
  MenuItem,
  TextareaAutosize,
  Paper,
} from "@material-ui/core";
// import Stepper from "@material-ui/core/Stepper";
// import Step from "@material-ui/core/Step";
// import StepLabel from "@material-ui/core/StepLabel";
// import Typography from "@material-ui/core/Typography";
import * as BsIcons from "react-icons/bs";
import * as AiIcons from "react-icons/ai";
import { switchUrls } from "../../api/index.js";
const baseURL = switchUrls("key");
const basegateURL = switchUrls("gateway");
// const baseURL = "http://localhost:3050";

function readCookie(name) {
  var nameEQ = name + "=";
  var ca = document.cookie.split(";");
  for (var i = 0; i < ca.length; i++) {
    var c = ca[i];
    while (c.charAt(0) == " ") c = c.substring(1, c.length);
    if (c.indexOf(nameEQ) == 0) return c.substring(nameEQ.length, c.length);
  }
  return null;
}
let usertoken = readCookie("token");
const Name = localStorage.getItem("name");
let token = usertoken;

function getSteps() {
  return ["Loss Details", "Location", "Date & Time of Loss", "Key Details"];
}

const steps = getSteps();

export default class ReportLost extends React.Component {
  constructor(props) {
    super(props);

    this.state = {
      sidebar: true,
      subMenu: true,
      alignment: "left",
      tag_number: "",
      tag_status: "lost",
      valuable_type: "",
      vehicle_brand: "",
      vehicle_model: "",
      vehicle_otherkeys: "",
      vehicle_licensenumber: "",
      keychain_description: "",
      identification_mark: "",
      other_nickname: "",
      other_noofkeys: "",
      owner_firstname: "",
      owner_lastname: "",
      owner_phone: "",
      owner_altphone: "",
      owner_email: "",
      owner_altemail: "",
      lost_text: "",
      lost_date: "",
      reward: "",
      rewardcurrency: "",
      lost_timeone: "",
      lost_timetwo: "",
      latitude: "",
      longitude: "",
      snackbaropen: false,
      snackbarmsg: "",
      activeStep: 0,
      chasisnumber: "",
      drivinglicense: "",
    };

    this.handleDropdownChange = this.handleDropdownChange.bind(this);
    this.showSidebar = this.showSidebar.bind(this);
    this.showSubMenu = this.showSubMenu.bind(this);
    // this.getCoordinates = this.getCoordinates.bind(this);
  }

  handleAlignment = (event, alignment) => this.setState({ alignment });

  handleDropdownChange(event) {
    this.setState({ valuable_type: event.target.value });
    if (event.target.value === "Car or Bike Keys") {
      document.getElementById("carorbikekeysselect").style.display = "block";
      document.getElementById("otherkeysselect").style.display = "none";
    } else if (event.target.value === "Other Keys") {
      document.getElementById("carorbikekeysselect").style.display = "none";
      document.getElementById("otherkeysselect").style.display = "block";
    }
  }

  componentDidMount() {
    // if (navigator.geolocation) {
    //   navigator.geolocation.getCurrentPosition(this.getCoordinates);
    // } else {
    //   alert("Geolocation is not supported by this browser.");
    // }

    if (this.state.valuable_type === "Car or Bike Keys") {
      document.getElementById("carorbikekeysselect").style.display = "block";
      document.getElementById("otherkeysselect").style.display = "none";
    } else if (this.state.valuable_type === "Other Keys") {
      document.getElementById("carorbikekeysselect").style.display = "none";
      document.getElementById("otherkeysselect").style.display = "block";
    }

    axios
      .get(
        `${baseURL}/api/key/getKeyDetails/` + this.props.match.params.tag_number
      )
      .then((response) => {
        console.log(response, "vavaga");
        this.setState({
          tag_number: response.data.tag_number,
          valuable_type: response.data.valuable_type,
          vehicle_brand: response.data.vehicle_brand,
          vehicle_licensenumber: response.data.vehicle_licensenumber,
          keychain_description: response.data.keychain_description,
          other_noofkeys: response.data.other_noofkeys,
          chasisnumber: response.data.chasisnumber,
          drivinglicense: response.data.drivinglicense,
        });
      })
      .catch((error) => {
        console.log(error);
      });

    axios
      .get(`${basegateURL}/api/getUserProfile`, {
        headers: { Authorization: usertoken },
      })
      .then((response) => {
        console.log("userprofile", response.data.first_name);
        this.setState({
          owner_firstname: response.data.first_name,
          owner_lastname: response.data.last_name,
          owner_phone: response.data.phone,
          owner_altphone: response.data.emergencyphone,
          owner_email: response.data.email,
        });
        console.log(this.state);
      })
      .catch((error) => {
        console.log(error);
      });
  }

  // getCoordinates(position) {
  //   console.log(position);
  //   this.setState({
  //     latitude: position.coords.latitude,
  //     longitude: position.coords.longitude,
  //   });
  // }

  sendlatitude = (data) => {
    console.log(data);
    this.setState({
      latitude: data.lat,
      longitude: data.lng,
    });
  };
  changeHandler = (e) => {
    this.setState({ [e.target.name]: e.target.value });
  };

  snackbarClose = (event) => {
    this.setState({ snackbaropen: false });
  };
  handleDateChange = (date) => {
    // console.log(date, "dates");
    const parsedDate = new Date(date);
    const selectedDate =
      parsedDate instanceof Date ? parsedDate.toLocaleDateString() : "";
    // console.log(selectedDate, "dates");
    this.setState({ lost_date: selectedDate });
  };
  handleFromTimeChange = (time) => {
    // console.log(time, "time one");
    const hour = time.$H ?? 0;
    const minute = time.$m ?? 0;
    const selectedTimeOne = `${String(hour).padStart(2, "0")}:${String(
      minute
    ).padStart(2, "0")}`;
    // console.log(selectedTimeOne, "dates");
    this.setState({ lost_timeone: selectedTimeOne });
  };
  handleToTimeChange = (time) => {
    // console.log(time, "time two");
    const hour = time.$H ?? 0;
    const minute = time.$m ?? 0;
    const selectedTimeTwo = `${String(hour).padStart(2, "0")}:${String(
      minute
    ).padStart(2, "0")}`;
    // console.log(selectedTimeTwo, "dates");
    this.setState({ lost_timetwo: selectedTimeTwo });
  };
  submitHandler = (e) => {
    e.preventDefault();
    console.log(this.state);
    axios
      .post(
        `${baseURL}/api/key/KeyReportLost/` +
          this.props.match.params.tag_number,
        this.state,
        { headers: { Authorization: usertoken } }
      )
      .then((response) => {
        console.log(response);
        if (response.data === "Pet is already reported lost") {
          this.setState({ snackbaropen: true, snackbarmsg: response.data });
          window.location.href = "/dashboard/" + localStorage.getItem("uid");
        } else if (response.data === "Lost details updated") {
          this.setState({ snackbaropen: true, snackbarmsg: response.data });
          window.location.href =
            "/#/key-poster/" + this.props.match.params.tag_number;
        } else if (response.data === "Key Reported Lost") {
          this.setState({ snackbaropen: true, snackbarmsg: response.data });
          window.location.href =
            "/#/key-poster/" + this.props.match.params.tag_number;
        } else if (response.data === "Key Report Lost Failed") {
          this.setState({ snackbaropen: true, snackbarmsg: response.data });
        }
      })
      .catch((error) => {
        console.log(error);
        this.setState({
          snackbaropen: true,
          snackbarmsg: "Report Lost Failed",
        });
      });
  };

  nextfieldclick() {
    document.getElementById("nextfieldpetname").focus();
  }

  // SelectedToggle = () => {
  //   const {
  //     lost_text,
  //     lost_date,
  //     latitude,
  //     longitude,
  //     lost_timeone,
  //     lost_timetwo,
  //   } = this.state;
  //   if (this.state.alignment === "left") {
  //     return (
  //       <div className="text-center">
  //         <textarea
  //           rows="4"
  //           className="loss-details-body text-center p-3"
  //           style={{
  //             fontSize: "16px",
  //             borderColor: "gray",
  //             marginTop: "150px",
  //             marginLeft: "0px",
  //           }}
  //           name="lost_text"
  //           placeholder="Give a brief account of loss. [Eg] Were you in a cab?"
  //           value={lost_text}
  //           onChange={this.changeHandler}
  //         ></textarea>
  //         <br />
  //         <br />
  //         <div>
  //           <Button
  //             variant="contained"
  //             className="bg-warning nextback nextbutton"
  //             onClick={() => this.setState({ alignment: "center" })}
  //             style={{ width: "200px" }}
  //           >
  //             Next
  //           </Button>
  //         </div>
  //       </div>
  //     );
  //   } else if (this.state.alignment === "center") {
  //     return (
  //       <div
  //         style={{
  //           display: "flex",
  //           flexDirection: "column",
  //           alignItems: "center",
  //         }}
  //       >
  //         <div style={{ "margin-left": "15%" }}>
  //           <div className="row">
  //             <MapContainer className="googlemaps"></MapContainer>
  //           </div>
  //           <div className="row mt-3 latlong">
  //             <div className="col">
  //               <span className="label-petdata float-left">Latitude</span>
  //               <br></br>
  //               <input
  //                 type="text"
  //                 className="form-control input-pet-details"
  //                 value={latitude}
  //                 name="latitude"
  //                 onChange={this.changeHandler}
  //               />
  //             </div>
  //             <div className="col">
  //               <span className="label-petdata float-left">Longitude</span>
  //               <br></br>
  //               <input
  //                 type="text"
  //                 className="form-control input-pet-details"
  //                 value={longitude}
  //                 name="longitude"
  //                 onChange={this.changeHandler}
  //               />
  //             </div>
  //           </div>
  //         </div>
  //         <div className="row mt-5 mb-4">
  //           <div className="col text-center">
  //             <Button
  //               variant="contained"
  //               className="bg-warning nextbutton mt-2"
  //               style={{ width: "200px" }}
  //               value="right"
  //               onClick={() => this.setState({ alignment: "right" })}
  //             >
  //               Next
  //             </Button>
  //             <br />
  //           </div>
  //           <div className="col text-center">
  //             <Button
  //               variant="contained"
  //               className="mt-2"
  //               style={{ width: "200px" }}
  //               onClick={() => this.setState({ alignment: "left" })}
  //             >
  //               Back
  //             </Button>
  //           </div>
  //         </div>
  //       </div>
  //     );
  //   } else if (this.state.alignment === "right") {
  //     return (
  //       <div>
  //         <div style={{ width: "250px", margin: "auto", marginTop: "50px" }}>
  //           <div className="col text-center">
  //             <input
  //               type="date"
  //               className="pl-2"
  //               name="lost_date"
  //               value={lost_date}
  //               onChange={this.changeHandler}
  //             />
  //             <br />
  //             <br />
  //             <br />
  //           </div>
  //           <h5>Set indicative time range of loss</h5>
  //           <br />
  //           <input
  //             type="time"
  //             className="pl-2"
  //             name="lost_timeone"
  //             value={lost_timeone}
  //             onChange={this.changeHandler}
  //           />
  //           <br />
  //           <br />
  //           to
  //           <br />
  //           <br />
  //           <input
  //             type="time"
  //             className="pl-2"
  //             name="lost_timetwo"
  //             value={lost_timetwo}
  //             onChange={this.changeHandler}
  //           />
  //         </div>
  //         <div className="row mt-5 mb-4">
  //           <div className="col text-center">
  //             <Button
  //               variant="contained"
  //               className="bg-warning nextbutton mt-2"
  //               onClick={this.nextfieldclick}
  //               style={{ width: "200px" }}
  //             >
  //               Next
  //             </Button>
  //             <br />
  //           </div>
  //           <div className="col text-center">
  //             <Button
  //               variant="contained"
  //               className="mt-2"
  //               onClick={() => this.setState({ alignment: "center" })}
  //               style={{ width: "200px" }}
  //             >
  //               Back
  //             </Button>
  //           </div>
  //         </div>
  //       </div>
  //     );
  //   } else {
  //     return <h1>null</h1>;
  //   }
  // };
  getContainerStyle = () => {
    const { activeStep } = this.state;

    if (activeStep === 2) {
      return { height: "93vh" };
    } else if (activeStep === 3) {
      return { height: "170vh" };
    } else {
      return { height: "93vh" };
    }
  };
  getStepContent = (stepIndex) => {
    const {
      sidebar,
      subMenu,
      lost_text,
      lost_date,
      latitude,
      longitude,
      lost_timeone,
      chasisnumber,
      drivinglicense,
      lost_timetwo,
      alignment,
      tag_number,
      valuable_type,
      vehicle_brand,
      vehicle_model,
      vehicle_otherkeys,
      vehicle_licensenumber,
      keychain_description,
      identification_mark,
      other_nickname,
      other_noofkeys,
      owner_firstname,
      owner_lastname,
      owner_phone,
      owner_altphone,
      owner_email,
      owner_altemail,
      reward,
      rewardcurrency,
    } = this.state;
    switch (this.state.activeStep) {
      case 0:
        return (
          <>
            <div
              style={{
                display: "flex",
                flexDirection: "column",
                alignItems: "center",
              }}
            >
              <TextareaAutosize
                rows={8}
                className="loss-details-body text-center p-3"
                // className="custom-textfield"
                variant="outlined"
                // InputProps={{ disableUnderline: true }}
                style={{
                  fontSize: "16px",
                  borderColor: "gray",
                  // marginTop: "5rem",
                  marginLeft: "0px",
                  width: "100%",
                  padding: "8px",
                  resize: "none",
                }}
                name="lost_text"
                label="Lost Text"
                placeholder="Give a brief account of loss."
                value={lost_text}
                onChange={this.changeHandler}
              />
              <div className="row mt-5 mb-4">
                <div className="col">
                  <Button
                    type="button"
                    onClick={() => {
                      window.location.href =
                        "/dashboard/" + localStorage.getItem("uid");
                    }}
                    variant="contained"
                    style={{ width: "200px" }}
                    className="submit-login cancel-btn mt-2"
                  >
                    CANCEL
                  </Button>
                </div>
                <div className="col">
                  <Button
                    variant="contained"
                    className="bg-warning nextback nextbutton mt-2"
                    onClick={this.handleNext}
                    style={{ width: "200px" }}
                  >
                    Next
                  </Button>
                </div>
              </div>
            </div>
          </>
        );
      case 1:
        return (
          <div
            style={{
              display: "flex",
              flexDirection: "column",
              alignItems: "center",
            }}
          >
            <div style={{ "margin-left": "10%" }}>
              <div className="row">
                <MapContainer
                  className="googlemaps"
                  sendlatitude={this.sendlatitude}
                ></MapContainer>
              </div>
              <div className="reportlost-location-input-cnt">
                <div className="reportlost-location-input-cnt-lat">
                  <span className="">Latitude</span>

                  <input
                    type="text"
                    className="reportlost-location-input"
                    value={latitude}
                    name="latitude"
                    onChange={this.changeHandler}
                    readOnly
                  />
                </div>

                <div className="reportlost-location-input-cnt-lon">
                  <span className="">Longitude</span>

                  <input
                    type="text"
                    className="reportlost-location-input"
                    value={longitude}
                    name="longitude"
                    onChange={this.changeHandler}
                    readOnly
                  />
                </div>
              </div>
            </div>
            <div className="reportlost-location-btns">
              <Button variant="contained" onClick={this.handleBack}>
                Back
              </Button>

              <Button
                variant="contained"
                value="right"
                onClick={this.handleNext}
                style={{ backgroundColor: "#ffc107" }}
              >
                Next
              </Button>

              <Button
                type="button"
                onClick={() => {
                  window.location.href =
                    "/dashboard/" + localStorage.getItem("uid");
                }}
                variant="contained"
              >
                CANCEL
              </Button>
            </div>
          </div>
        );
      case 2:
        return (
          <div className="date-time-loss">
            <div
              style={{
                display: "flex",
                flexDirection: "column",
                alignItems: "center",
              }}
            >
              <DatePicker
                label="Date and Time of Loss"
                onChange={this.handleDateChange}
                // value={this.state.lost_date}
                // maxDate={maxSelectableDate}
                // renderInput={(params) => <TextField {...params} />}
              />
            </div>
            <div class="time-picker-container">
              <div>
                <label>From Time of Loss :</label>
                <TimePicker
                  onChange={this.handleFromTimeChange}
                  // value={this.state.lost_timeone}
                />
              </div>
              <div>
                <label>To Time of Loss :</label>
                <TimePicker
                  onChange={this.handleToTimeChange}
                  // value={this.state.lost_timetwo}
                />
              </div>
            </div>

            <div className="date-time-loss-btns">
              <Button
                type="button"
                onClick={() => {
                  window.location.href =
                    "/dashboard/" + localStorage.getItem("uid");
                }}
                variant="contained"
                style={{ width: "200px" }}
                className="submit-login cancel-btn mt-2"
              >
                CANCEL
              </Button>
              <Button
                variant="contained"
                className="bg-warning nextbutton mt-2"
                onClick={this.handleNext}
                style={{ width: "200px" }}
              >
                Next
              </Button>
              <Button
                variant="contained"
                className="mt-2"
                onClick={this.handleBack}
                style={{ width: "200px" }}
              >
                Back
              </Button>
            </div>
          </div>
        );

      case 3:
        return (
          <div className="reportlost-container">
            <span className="reportlost-container-pet-details-span">
              <h4>Key Details</h4>

              <span className="reportlost-one-line">
                <div className="reportlost-container-pet-details-span-input-cnt">
                  {/* <span>Owner ID *</span> */}

                  <TextField
                    type="textbox"
                    variant="outlined"
                    label="Owner ID"
                    value={tag_number}
                    name="tag_number"
                    InputProps={{
                      readOnly: true,
                    }}
                  />
                </div>
                <div className="reportlost-container-pet-details-span-input-cnt">
                  {/* <span>Valuable Type</span> */}

                  <FormControl>
                    <InputLabel>Valuable Type</InputLabel>
                    <Select
                      labelId="demo-simple-select-label"
                      id="reward-id"
                      value={valuable_type}
                      label="valuable_type"
                      variant="outlined"
                      disabled
                      onChange={this.handleDropdownChange}
                    >
                      <MenuItem value="CarorBikeKeys">
                        Car or Bike Keys
                      </MenuItem>
                      <MenuItem value="OtherKeys">Other Keys</MenuItem>
                    </Select>
                  </FormControl>
                </div>
              </span>

              {/* <div></div> */}

              <span className="reportlost-one-line">
                <div className="reportlost-container-pet-details-span-input-cnt">
                  <TextField
                    type="text"
                    value={other_noofkeys}
                    name="other_noofkeys"
                    variant="outlined"
                    label="No of Keys"
                    InputProps={{
                      readOnly: true,
                    }}
                  />
                </div>

                <div className="reportlost-container-pet-details-span-input-cnt">
                  <TextField
                    type="text"
                    value={vehicle_licensenumber}
                    name="vehicle_licensenumber"
                    InputProps={{
                      readOnly: true,
                    }}
                    variant="outlined" // You can change this to 'filled' or 'standard' as needed
                    label="Vehicle License Plate number"
                  />
                </div>
              </span>
              <span className="reportlost-one-line">
                <div className="reportlost-container-pet-details-span-input-cnt">
                  <TextField
                    type="text"
                    value={vehicle_brand}
                    name="vehicle_brand"
                    InputProps={{
                      readOnly: true,
                    }}
                    variant="outlined"
                    label="Vehicle Brand & Model"
                  />
                </div>

                <div className="reportlost-container-pet-details-span-input-cnt">
                  <TextField
                    type="text"
                    value={chasisnumber}
                    name="chasisnumber"
                    InputProps={{
                      readOnly: true,
                    }}
                    variant="outlined"
                    label="Chasis number"
                  />
                </div>
              </span>
              <span className="reportlost-one-line">
                <div className="reportlost-container-pet-details-span-input-cnt">
                  <TextField
                    type="text"
                    value={drivinglicense}
                    name="drivinglicense"
                    required
                    variant="outlined"
                    label="Driving License number"
                    InputProps={{
                      readOnly: true,
                    }}
                  />
                </div>
                <div className="reportlost-container-pet-details-span-input-cnt">
                  <TextField
                    type="text"
                    // id="nextfieldpetname"
                    label="Keychain Description"
                    variant="outlined"
                    InputProps={{
                      readOnly: true,
                      // maxLength: 20,
                    }}
                    name="keychain_description"
                    value={keychain_description}
                    onChange={this.changeHandler}
                  />
                </div>
              </span>
            </span>

            <span className="reportlost-container-pet-parent-details-span">
              <h4 className="">key Owner Details</h4>

              <span className="reportlost-one-line">
                <div className="reportlost-container-pet-parent-details-span-input-cnt">
                  <TextField
                    type="text"
                    maxLength="10"
                    name="owner_firstname"
                    value={owner_firstname}
                    onChange={this.changeHandler}
                    variant="outlined"
                    label="First Name"
                    InputProps={{
                      readOnly: true,
                    }}
                  />
                </div>

                <div className="reportlost-container-pet-parent-details-span-input-cnt">
                  <TextField
                    type="text"
                    maxLength="10"
                    name="owner_lastname"
                    value={owner_lastname}
                    onChange={this.changeHandler}
                    variant="outlined"
                    label="Last Name"
                    InputProps={{
                      readOnly: true,
                    }}
                  />
                </div>
              </span>

              <span className="reportlost-one-line">
                <div className="reportlost-container-pet-parent-details-span-input-cnt">
                  <TextField
                    type="text"
                    maxLength="20"
                    name="owner_email"
                    value={owner_email}
                    onChange={this.changeHandler}
                    variant="outlined"
                    label="Email ID"
                    InputProps={{
                      readOnly: true,
                    }}
                  />
                </div>

                <div className="reportlost-container-pet-parent-details-span-input-cnt">
                  <TextField
                    type="text"
                    maxLength="20"
                    name="owner_phone"
                    value={owner_phone}
                    onChange={this.changeHandler}
                    variant="outlined"
                    label="Emergency Phone Number 1"
                    InputProps={{
                      readOnly: true,
                    }}
                  />
                </div>
              </span>

              <span className="reportlost-one-line">
                <div className="reportlost-container-pet-parent-details-span-input-cnt">
                  <TextField
                    type="text"
                    maxLength="20"
                    name="owner_altphone"
                    value={owner_altphone}
                    onChange={this.changeHandler}
                    variant="outlined"
                    label="Emergency Phone Number 2"
                    InputProps={{
                      readOnly: true,
                    }}
                  />
                </div>
              </span>
            </span>

            <div
              style={{
                display: "flex",
                flexDirection: "column",
                alignItems: "stretch",
              }}
            >
              <h4>
                <i className="fa fa-gift"></i>
                <span>Owner Reward</span>
              </h4>

              <div className="reportlost-container-currency">
                <div className="reportlost-container-owner-reward-span-select-cnt">
                  <FormControl>
                    <InputLabel
                      id="demo-simple-select-label"
                      style={{ marginTop: "-7px", marginLeft: "13px" }}
                    >
                      Currency type
                    </InputLabel>
                    <Select
                      name="rewardcurrency"
                      value={rewardcurrency}
                      id="reward-id"
                      className="reward-class"
                      labelId="demo-simple-select-label"
                      label="Currency type"
                      onChange={this.changeHandler}
                      variant="outlined"
                    >
                      <MenuItem value="" disabled>
                        Select currency
                      </MenuItem>
                      <MenuItem hidden></MenuItem>
                      <MenuItem value="Rupees">Indian Rupees</MenuItem>
                      <MenuItem value="U.S. dollar">U.S. dollar</MenuItem>
                      <MenuItem value="Euro">Euro</MenuItem>
                      <MenuItem value="Dirham">Dirham</MenuItem>
                      <MenuItem value="Dinar">Dinar</MenuItem>
                      <MenuItem value="Yen">Yen</MenuItem>
                      <MenuItem value="Afghani">Afghani</MenuItem>
                      <MenuItem value="Lek">Lek</MenuItem>
                      <MenuItem value="New Kwanza">New Kwanza</MenuItem>
                      <MenuItem value="Dram">Dram</MenuItem>
                      <MenuItem value="Bahamian dollar">
                        Bahamian dollar
                      </MenuItem>
                      <MenuItem value="Bahrain dinar">Bahrain dinar</MenuItem>
                      <MenuItem value="Taka">Taka</MenuItem>
                      <MenuItem value="Barbados dollar">
                        Barbados dollar
                      </MenuItem>
                      <MenuItem value="Belorussian ruble">
                        Belorussian ruble
                      </MenuItem>
                      <MenuItem value="Belize dollar">Belize dollar</MenuItem>
                      <MenuItem value="Ngultrum">Ngultrum</MenuItem>
                      <MenuItem value="Boliviano">Boliviano</MenuItem>
                      <MenuItem value="Convertible Mark">
                        Convertible Mark
                      </MenuItem>
                      <MenuItem value="Pula">Pula</MenuItem>
                      <MenuItem value="Real">Real</MenuItem>
                      <MenuItem value="Brunei dollar">Brunei dollar</MenuItem>
                      <MenuItem value="Lev">Lev</MenuItem>
                      <MenuItem value="Burundi franc">Burundi franc</MenuItem>
                      <MenuItem value="Riel">Riel</MenuItem>
                      <MenuItem value="Canadian dollar">
                        Canadian dollar
                      </MenuItem>
                      <MenuItem value="Cape Verdean escudo">
                        Cape Verdean escudo
                      </MenuItem>
                      <MenuItem value="Chilean Peso">Chilean Peso</MenuItem>
                      <MenuItem value="Chinese Yuan">Chinese Yuan</MenuItem>
                      <MenuItem value="Colombian Peso">Colombian Peso</MenuItem>
                      <MenuItem value="Franc">Franc</MenuItem>
                      <MenuItem value="United States dollar">
                        United States dollar
                      </MenuItem>
                      <MenuItem value="Colón">Colón</MenuItem>
                      <MenuItem value="Croatian">Croatian</MenuItem>
                      <MenuItem value="Cuban Peso">Cuban Peso</MenuItem>
                      <MenuItem value="Koruna">Koruna</MenuItem>
                      <MenuItem value="Danish Krone">Danish Krone</MenuItem>
                      <MenuItem value="Djiboutian franc">
                        Djiboutian franc
                      </MenuItem>
                      <MenuItem value="Dominican Peso">Dominican Peso</MenuItem>
                      <MenuItem value="Egyptian pound">Egyptian pound</MenuItem>
                      <MenuItem value="Colón; U.S. dollar">
                        Colón; U.S. dollar
                      </MenuItem>
                      <MenuItem value="Nakfa">Nakfa</MenuItem>
                      <MenuItem value="Birr">Birr</MenuItem>
                      <MenuItem value="Fiji dollar">Fiji dollar</MenuItem>
                      <MenuItem value="Dalasi">Dalasi</MenuItem>
                      <MenuItem value="Lari">Lari</MenuItem>
                      <MenuItem value="Cedi">Cedi</MenuItem>
                      <MenuItem value="Quetzal">Quetzal</MenuItem>
                      <MenuItem value="Guinean franc">Guinean franc</MenuItem>
                      <MenuItem value="Guyanese dollar">
                        Guyanese dollar
                      </MenuItem>
                      <MenuItem value="Gourde">Gourde</MenuItem>
                      <MenuItem value="Lempira">Lempira</MenuItem>
                      <MenuItem value="Forint">Forint</MenuItem>
                      <MenuItem value="Icelandic króna">
                        Icelandic króna
                      </MenuItem>
                      <MenuItem value="Rupiah">Rupiah</MenuItem>
                      <MenuItem value="Iraqi Dinar">Iraqi Dinar</MenuItem>
                      <MenuItem value="Shekel">Shekel</MenuItem>
                      <MenuItem value="Jamaican dollar">
                        Jamaican dollar
                      </MenuItem>
                      <MenuItem value="Jordanian dinar">
                        Jordanian dinar
                      </MenuItem>
                      <MenuItem value="Tenge">Tenge</MenuItem>
                      <MenuItem value="Kenya shilling">Kenya shilling</MenuItem>
                      <MenuItem value="Kiribati dollar">
                        Kiribati dollar
                      </MenuItem>
                      <MenuItem value="Won">Won</MenuItem>
                      <MenuItem value="Kuwaiti Dinar">Kuwaiti Dinar</MenuItem>
                      <MenuItem value="Som">Som</MenuItem>
                      <MenuItem value="New Kip">New Kip</MenuItem>
                      <MenuItem value="Lats">Lats</MenuItem>
                      <MenuItem value="Lebanese pound">Lebanese pound</MenuItem>
                      <MenuItem value="Maluti">Maluti</MenuItem>
                      <MenuItem value="Liberian dollar">
                        Liberian dollar
                      </MenuItem>
                      <MenuItem value="Libyan dinar">Libyan dinar</MenuItem>
                      <MenuItem value="Litas">Litas</MenuItem>
                      <MenuItem value="Denar">Denar</MenuItem>
                      <MenuItem value="Malagasy Ariary">
                        Malagasy Ariary
                      </MenuItem>
                      <MenuItem value="Ringgit">Ringgit</MenuItem>
                      <MenuItem value="Rufiyaa">Rufiyaa</MenuItem>
                      <MenuItem value="Ouguiya">Ouguiya</MenuItem>
                      <MenuItem value="Mauritian rupee">
                        Mauritian rupee
                      </MenuItem>
                      <MenuItem value="Mexican peso">Mexican peso</MenuItem>
                      <MenuItem value="Leu">Leu</MenuItem>
                      <MenuItem value="Togrog">Togrog</MenuItem>
                      <MenuItem value="Metical">Metical</MenuItem>
                      <MenuItem value="Kyat">Kyat</MenuItem>
                      <MenuItem value="Namibian dollar">
                        Namibian dollar
                      </MenuItem>
                      <MenuItem value="Australian dollar">
                        Australian dollar
                      </MenuItem>
                      <MenuItem value="Nepalese rupee">Nepalese rupee</MenuItem>
                      <MenuItem value="New Zealand dollar">
                        New Zealand dollar
                      </MenuItem>
                      <MenuItem value="Gold cordoba">Gold cordoba</MenuItem>
                      <MenuItem value="Naira">Naira</MenuItem>
                      <MenuItem value="Norwegian krone">
                        Norwegian krone
                      </MenuItem>
                      <MenuItem value="Omani rial">Omani rial</MenuItem>
                      <MenuItem value="Pakistani rupee">
                        Pakistani rupee
                      </MenuItem>
                      <MenuItem value="Palestine Pound">
                        Palestine Pound
                      </MenuItem>
                      <MenuItem value="Balboa; U.S. dollar">
                        Balboa; U.S. dollar
                      </MenuItem>
                      <MenuItem value="Kina">Kina</MenuItem>
                      <MenuItem value="Guaraní">Guaraní</MenuItem>
                      <MenuItem value="Nuevo sol (1991)">
                        Nuevo sol (1991)
                      </MenuItem>
                      <MenuItem value="Peso">Peso</MenuItem>
                      <MenuItem value="Zloty">Zloty</MenuItem>
                      <MenuItem value="Qatari riyal">Qatari riyal</MenuItem>
                      <MenuItem value="Romanian Rupee">Romanian Rupee</MenuItem>
                      <MenuItem value="Ruble">Ruble</MenuItem>
                      <MenuItem value="Rwandan franc">Rwandan franc</MenuItem>
                      <MenuItem value="East Caribbean dollar">
                        {" "}
                        East Caribbean dollar
                      </MenuItem>
                      <MenuItem value="Tala">Tala</MenuItem>
                      <MenuItem value="Dobra">Dobra</MenuItem>
                      <MenuItem value="Riyal">Riyal</MenuItem>
                      <MenuItem value="Serbian Dinar">Serbian Dinar</MenuItem>
                      <MenuItem value="Seychelles rupee">
                        Seychelles rupee
                      </MenuItem>
                      <MenuItem value="Leone">Leone</MenuItem>
                      <MenuItem value="Singapore dollar">
                        Singapore dollar
                      </MenuItem>
                      <MenuItem value="Solomon Islands dollar">
                        Solomon Islands dollar
                      </MenuItem>
                      <MenuItem value="Somali shilling">
                        Somali shilling
                      </MenuItem>
                      <MenuItem value="Rand">Rand</MenuItem>
                      <MenuItem value="Sri Lankan rupee">
                        Sri Lankan rupee
                      </MenuItem>
                      <MenuItem value="Sudanese Pound">Sudanese Pound</MenuItem>
                      <MenuItem value="Surinamese dollar">
                        Surinamese dollar
                      </MenuItem>
                      <MenuItem value="Lilangeni">Lilangeni</MenuItem>
                      <MenuItem value="Krona">Krona</MenuItem>
                      <MenuItem value="Swiss franc">Swiss franc</MenuItem>
                      <MenuItem value="Syrian pound">Syrian pound</MenuItem>
                      <MenuItem value="Taiwan dollar">Taiwan dollar</MenuItem>
                      <MenuItem value="somoni">somoni</MenuItem>
                      <MenuItem value="Tanzanian shilling">
                        Tanzanian shilling
                      </MenuItem>
                      <MenuItem value="Baht">Baht</MenuItem>
                      <MenuItem value="CFA Franc">CFA Franc</MenuItem>
                      <MenuItem value="Pa’anga">Pa’anga</MenuItem>
                      <MenuItem value="Trinidad and Tobago dollar">
                        Trinidad and Tobago dollar
                      </MenuItem>
                      <MenuItem value="Tunisian dinar">Tunisian dinar</MenuItem>
                      <MenuItem value="Turkish lira (YTL)">
                        Turkish lira (YTL)
                      </MenuItem>
                      <MenuItem value="Manat">Manat</MenuItem>
                      <MenuItem value="Tuvaluan Dollar">
                        Tuvaluan Dollar
                      </MenuItem>
                      <MenuItem value="Ugandan new shilling">
                        Ugandan new shilling
                      </MenuItem>
                      <MenuItem value="Hryvnia">Hryvnia</MenuItem>
                      <MenuItem value="U.A.E. Dirham">U.A.E. Dirham</MenuItem>
                      <MenuItem value="Pound sterling">Pound sterling</MenuItem>
                      <MenuItem value="Dollar">Dollar</MenuItem>
                      <MenuItem value="Uruguay peso">Uruguay peso</MenuItem>
                      <MenuItem value="Uzbekistani sum">
                        Uzbekistani sum
                      </MenuItem>
                      <MenuItem value="Vatu">Vatu</MenuItem>
                      <MenuItem value="Bolivar">Bolivar</MenuItem>
                      <MenuItem value="Dong">Dong</MenuItem>
                      <MenuItem value="Rial">Rial</MenuItem>
                      <MenuItem value="Kwacha">Kwacha</MenuItem>
                    </Select>
                  </FormControl>
                </div>
                <div className="reportlost-container-owner-reward-span-select-cnt">
                  <TextField
                    type="number"
                    name="reward"
                    variant="outlined"
                    label="reward"
                    value={reward}
                    onChange={this.changeHandler}
                  />
                </div>
              </div>
            </div>
            <div className="reportlost-container-owner-reward-btns">
              <Button variant="contained" onClick={this.handleBack}>
                Back
              </Button>

              <Button
                variant="contained"
                type="submit"
                style={{ backgroundColor: "#ffc107" }}
              >
                Submit
              </Button>

              <Button
                type="button"
                onClick={() => {
                  window.location.href =
                    "/dashboard/" + localStorage.getItem("uid");
                }}
                variant="contained"
              >
                CANCEL
              </Button>
            </div>
          </div>
        );
    }
  };

  handleNext = () => {
    this.setState((prevActiveStep) => {
      console.log("prev", prevActiveStep);
      return {
        activeStep: prevActiveStep.activeStep + 1,
      };
    });
  };

  handleBack = () => {
    this.setState((prevActiveStep) => {
      return {
        activeStep: prevActiveStep.activeStep - 1,
      };
    });
  };

  handleReset = () => {
    this.setState({ activeStep: 0 });
  };
  showSidebar(e) {
    e.preventDefault();

    this.setState((currentState) => ({
      sidebar: !currentState.sidebar,
    }));
  }

  showSubMenu(e) {
    e.preventDefault();

    this.setState((currentState) => ({
      subMenu: !currentState.subMenu,
    }));
  }
  render() {
    const {
      sidebar,
      subMenu,
      alignment,
      tag_number,
      valuable_type,
      vehicle_brand,
      vehicle_model,
      vehicle_otherkeys,
      vehicle_licensenumber,
      keychain_description,
      identification_mark,
      other_nickname,
      other_noofkeys,
      owner_firstname,
      owner_lastname,
      owner_phone,
      owner_altphone,
      owner_email,
      owner_altemail,
      reward,
      rewardcurrency,
    } = this.state;

    return (
      <>
        <Navbar />
        <div className="reportLost-main-wrapper">
          <div className="reportLost-left-wrapper">
            <div className="reportLost-sidebar-toggle-btn-wrapper">
              <button
                className="reportLost-sidebar-toggle-btn"
                onClick={this.showSidebar}
              >
                {sidebar ? (
                  <BsIcons.BsLayoutSidebarInset />
                ) : (
                  <BsIcons.BsLayoutSidebarInsetReverse />
                )}
              </button>
            </div>

            <div
              className={
                sidebar
                  ? "reportLost-sidebar-container-hide"
                  : "reportLost-sidebar-container"
              }
            >
              <button
                className="reportLost-sidebar-menu-link"
                onClick={this.showSubMenu}
              >
                MY TAGS
                {subMenu ? (
                  <AiIcons.AiFillCaretDown />
                ) : (
                  <AiIcons.AiFillCaretUp />
                )}
              </button>

              <ul
                className={
                  subMenu
                    ? "reportLost-sidebar-menu-ul-hide"
                    : "reportLost-sidebar-menu-ul"
                }
              >
                <li>
                  <button
                    className="reportLost-sidebar-menu-ul-links"
                    onClick={() => {
                      window.location.href =
                        "/dashboard/" + this.props.match.params.tag_number;
                    }}
                  >
                    ALL TAGS
                  </button>
                </li>

                <li>
                  <button
                    className="reportLost-sidebar-menu-ul-links"
                    onClick={() => {
                      window.location.href =
                        "/dashboard/" + this.props.match.params.tag_number;
                    }}
                  >
                    ACTIVE | INACTIVE TAGS
                  </button>
                </li>

                <li>
                  <button
                    className="reportLost-sidebar-menu-ul-links"
                    onClick={() => {
                      window.location.href =
                        "/dashboard/" + this.props.match.params.tag_number;
                    }}
                  >
                    LOST | FOUND TAGS
                  </button>
                </li>
              </ul>

              <button
                className="reportLost-sidebar-menu-link"
                onClick={() => {
                  window.location.href =
                    "/dashboard/" + this.props.match.params.tag_number;
                }}
              >
                ACTIVATE TAG
              </button>
              {/* <Dialog
                PaperProps={{
                  style: { borderRadius: 15 },
                }}
                disableBackdropClick
                open={show}
                fullWidth
                maxWidth="xs"
                onClose={handleClose}
                aria-labelledby="form-dialog-title"
              >
                <DialogContent>
                  <h2 className="dashboard-activateTag-popup">
                    Tag Activation
                  </h2>

                  <div
                    style={{
                      display: "flex",
                      justifyContent: "center",
                      alignItems: "center",
                    }}
                  >
                    <input
                      className="dashboard-input__field__dialogs"
                      onKeyUp={(e) => {
                        e.target.value = e.target.value.toUpperCase();
                      }}
                      value={tagNumber ? tagNumber : ""}
                      onChange={(e) => setTagNumber(e.target.value)}
                      placeholder="Owner ID"
                    />

                    <img
                      src={qrcode}
                      alt="qr code"
                      className="qrbutton"
                      onClick={(e) => setShow5(true)}
                    />
                  </div>

                  <div
                    style={{
                      padding: "1em",
                      marginBottom: "1px",
                      display: "flex",
                      justifyContent: "space-evenly",
                      alignItems: "center",
                    }}
                  >
                    <button
                      className="dashboard-verifyOtp-button"
                      onClick={activateTag}
                    >
                      ACTIVATE
                    </button>

                    <button
                      className="dashboard-otp-buttons"
                      onClick={handleClose}
                    >
                      CANCEL
                    </button>
                  </div>
                </DialogContent>
              </Dialog> */}
              <button
                className="reportLost-sidebar-menu-link"
                onClick={() => {
                  window.location.href =
                    "/scan-notification/" + localStorage.getItem("uid");
                }}
              >
                NOTIFICATIONS
              </button>
            </div>
          </div>

          <div
            className="reportlost-bag-container"
            style={this.getContainerStyle()}
          >
            <div className="background-image">
              <div className="key-activation-container">
                <form onSubmit={this.submitHandler}>
                  <Stepper
                    activeStep={this.state.activeStep}
                    alternativeLabel
                    className=""
                    style={{ background: "whitesmoke" }}
                  >
                    {steps.map((label) => (
                      <Step key={label}>
                        <StepLabel>{label}</StepLabel>
                      </Step>
                    ))}
                  </Stepper>
                  <div
                    className="container uppercontainer  py-3 px-0 mt-4 p-info text-center reportlostheight"
                    style={{
                      // height: this.state.activeStep === 3 ? "100%" : "570px",
                      backgroundColor: "whitesmoke",
                      // padding: "20px",
                    }}
                  >
                    {this.getStepContent(this.state.activeStep)}
                  </div>
                  <Snackbar
                    open={this.state.snackbaropen}
                    autoHideDuration={3000}
                    onClose={this.snackbarClose}
                    message={this.state.snackbarmsg}
                    action={[
                      <IconButton
                        arial-label="Close"
                        color="inherit"
                        onClick={this.snackbarClose}
                      >
                        X
                      </IconButton>,
                    ]}
                  />
                </form>
              </div>
            </div>
          </div>
        </div>
      </>
    );
  }
}
