import React from "react";
import "bootstrap/dist/css/bootstrap.min.css";
import "../keydetails/keydetails.component.css";
// import Logo from "../../assets/tag8logoblack.png";
// import Keyicon from "../../assets/keyicon.png";
// import CatDog from "../../assets/keyicon.png";
import "font-awesome/css/font-awesome.min.css";
import Button from "@material-ui/core/Button";
import Card from "@material-ui/core/Card";
import "../keyposter/keyposter.component.css";
import axios from "axios";
import { Link } from "react-router-dom";
import html2canvas from "html2canvas";
// import email from "../../assets/email.png";
// import call from "../../assets/call.png";
// import phone from "../../assets/phone-call.png";
import * as FaIcons from "react-icons/fa";
import * as GiIcons from "react-icons/gi";
// import money from "../../assets/money.png";
import { exportComponentAsPNG } from "react-component-export-image";
import { switchUrls } from "../../api/index.js";
const baseURL = switchUrls("key");

// const baseURL = "http://localhost:3050";

function toTitleCase(str) {
  return str.replace(/\w\S*/g, function (txt) {
    return txt.charAt(0).toUpperCase() + txt.substr(1).toLowerCase();
  });
}
export default class KeyPoster extends React.Component {
  constructor(props) {
    super(props);

    this.state = {
      tag_number: this.props.match.params.tag_number,
      owner_firstname: "",
      owner_lastname: "",
      valuable_type: "",
      keychain_description: "",
      identification_mark: "",
      owner_phone: "",
      lost_text: "",
      lost_date: "",
      reward: "",
      rewardcurrency: "",
      lost_timeone: "",
      lost_timetwo: "",
      latitude: "",
      longitude: "",
      address: "",
      key_imagelocation: "",
      other_noofkeys: "",
      owner_email: "",
      owner_phonecode: "",
      active: false,
      showdiv: false,
    };
    this.toggleMenu = this.toggleMenu.bind(this);
  }

  // showOptions() {
  //   if (this.state.showdiv) {
  //     document.getElementById("bdiv").style.bottom = "-14.5rem";
  //     document.getElementById("aup").style.display = "block";
  //     document.getElementById("adown").style.display = "none";

  //     this.setState({ showdiv: false });
  //   } else {
  //     document.getElementById("bdiv").style.bottom = "0";
  //     document.getElementById("aup").style.display = "none";
  //     document.getElementById("adown").style.display = "block";

  //     this.setState({ showdiv: true });
  //   }
  // }
  showOptions() {
    if (this.state.showdiv) {
      document.getElementById("bdiv").style.bottom = "-14.5rem";
      document.getElementById("aup").style.display = "block";
      document.getElementById("adown").style.display = "none";

      this.setState({ showdiv: false });
    } else {
      document.getElementById("bdiv").style.bottom = "0";
      document.getElementById("aup").style.display = "none";
      document.getElementById("adown").style.display = "block";

      this.setState({ showdiv: true });
    }
  }
  downloadposter() {
    let elem = document.getElementById("contentToConvert");
    html2canvas(elem, { useCORS: true, allowTaint: true }).then(function (
      canvas
    ) {
      let generatedImage = canvas
        .toDataURL("image/png")
        .replace("image/png", "image/octet-stream");
      let a = document.createElement("a");
      a.href = generatedImage;
      a.download = `key-poster.png`;
      a.click();
    });
  }
  toggleMenu() {
    this.setState({
      active: !this.state.active,
    });
  }
  componentDidMount() {
    // this.listener = document.addEventListener("scroll", (e) => {
    //   var scrolled = document.scrollingElement.scrollTop;
    //   if (scrolled >= 20) {
    //     // if (this.state.status !== "scroll") {
    //     //   this.setState({ status: "scroll" });
    //     // }
    //     document.getElementById("genie-nav").style.backgroundColor = "white";
    //   } else {
    //     // if (this.state.status !== "top") {
    //     //   this.setState({ status: "top" });
    //     // }
    //     document.getElementById("genie-nav").style.backgroundColor =
    //       "transparent";
    //   }
    // });
    axios
      .get(
        `${baseURL}/api/key/getreportlocation/` +
          this.props.match.params.tag_number
      )
      .then((response) => {
        console.log("address", response);
        this.setState({
          address: response.data.data[0].address,
          latitude: response.data.data[0].latitude,
          longitude: response.data.data[0].longitude,
        });
      })
      .catch((error) => {
        console.log(error);
      });

    axios
      .get(
        `${baseURL}/api/key/getKeyImagePoster/` +
          this.props.match.params.tag_number
      )
      .then((response) => {
        console.log("hedbgjvdj", response);
        this.setState({
          key_imagelocation: response.data.key_imagelocation,
        });
      })
      .catch((error) => {
        console.log(error);
      });

    axios
      .get(
        `${baseURL}/api/key/getReportLost/` + this.props.match.params.tag_number
      )
      .then((response) => {
        console.log(response);
        this.setState({
          owner_firstname: response.data.owner_firstname,
          owner_lastname: response.data.owner_lastname,
          owner_phone: response.data.owner_phone,
          owner_email: response.data.owner_email,
          lost_date: response.data.lost_date,
          address: response.data.address,
          rewardcurrency: response.data.rewardcurrency,
          reward: response.data.reward,
        });
      })
      .catch((error) => {
        console.log(error);
      });

    //   this.listener = document.addEventListener("scroll", (e) => {
    //     var scrolled = document.scrollingElement.scrollTop;

    //     if (scrolled >= 20) {
    //       document.getElementById("genie-nav").style.backgroundColor = "white";
    //     } else {
    //       document.getElementById("genie-nav").style.backgroundColor =
    //         "transparent";
    //     }
    //   });
  }

  render() {
    const {
      owner_firstname,
      owner_lastname,
      other_noofkeys,
      valuable_type,
      keychain_description,
      identification_mark,
      owner_phone,
      owner_email,
      owner_phonecode,
      reward,
      rewardcurrency,
      lost_date,
      address,
      latitude,
      longitude,
      key_imagelocation,
      active,
    } = this.state;
    // console.log(this.state.active);

    var image;
    // console.log("dwdw", this.state);
    const initial = this.state.tag_number.substring(0, 3);
    console.log("nef", initial);
    if (initial === "KEY" || initial === "TGY") {
      if (this.state.key_imagelocation == undefined) {
        image = this.state.key_imagelocation;
      } else {
        image = "https://storage.googleapis.com/pettag/qr/keyicon.png";
      }
    }
    console.log("los", image);
    return (
      <>
        <div className="genie-nav" id="genie-nav">
          <img
            src={"https://storage.googleapis.com/pettag/qr/tag8logoblack.png"}
            alt="logo"
            style={{ width: "5rem" }}
          />

          <div className="menu-bars" onClick={() => this.toggleMenu()}>
            <GiIcons.GiHamburgerMenu />
          </div>
        </div>

        <div
          className={
            active ? "poster-utility" : "poster-utility poster-utility-hide"
          }
        >
          {/* <button className="poster-utility-btns" onClick={this.downloadposter}>
            Download
          </button> */}

          <a
            className="poster-utility-btns"
            href={"/#/edit-report-lost/" + this.props.match.params.tag_number}
          >
            Edit
          </a>

          <a
            onClick={() => {
              window.location.href =
                "/dashboard/" + localStorage.getItem("uid");
            }}
            className="poster-utility-btns"
          >
            Go To My Tags
          </a>
        </div>

        <div id="contentToConvert" className="pb-3">
          <Card className="mb-5 key-mat-card">
            <div className="lost-info-detail-header row bg-gray text-center">
              <p className="header-font-key header-text-key lost-data-header">
                Thank You for Finding{" "}
                <p className="header-font-key header-text-key lost-data-header">
                  keys
                  {/* {product_category == "-" ? "VALUABLE" : "KEYS"}{" "} */}
                </p>
              </p>
            </div>

            <div className="row  text-center flex">
              <div className="col-md-5 pr-5 img-container colorw">
                <div className="main-img2">
                  <img
                    src={
                      key_imagelocation
                        ? `data:image/png;base64,${key_imagelocation}`
                        : "https://storage.googleapis.com/pettag/qr/keyicon.png"
                    }
                    alt="valuable"
                    className="mat-card-image2"
                  />
                </div>
              </div>
              <div className="col-md-7 colorw">
                <span className="reportfound-message">
                  <span className="reportfound-message-header">
                    This valuable belongs to
                  </span>

                  <p className="reportfound-message-content">
                    <span className="reportfound-message-content-icon">
                      <FaIcons.FaUserCircle />
                    </span>

                    <span className="reportfound-message-content-name">
                      {owner_firstname && owner_firstname !== "-"
                        ? owner_firstname
                        : null}{" "}
                      {owner_lastname && owner_lastname !== "-"
                        ? owner_lastname
                        : null}
                    </span>
                  </p>

                  <p className="reportfound-message-ownerid">
                    Owner ID :<span>{this.props.match.params.tag_number}</span>
                  </p>
                </span>
              </div>
            </div>
            {lost_date || address ? (
              <div>
                <div
                  style={{
                    width: "100%",
                    display: "flex",
                    flexDirection: "column",
                    justifyContent: "center",
                    alignItems: "center",
                    padding: "1rem",
                    gap: "1rem",
                  }}
                >
                  {/* <div className="card bg-light mb-3" style="max-width: 18rem;">
                    <div className="card-header">Header</div>
                    <div className="card-body">
                      <h5 className="card-title">Light card title</h5>
                      <p className="card-text">
                        Some quick example text to build on the card title and
                        make up the bulk of the card's content.
                      </p>
                    </div>
                  </div> */}

                  <p className="header-font-key lost-info-header">
                    Lost Reported Data
                  </p>

                  <span className="keyposter-lost-details-span">
                    <span className="keyposter-lost-details">
                      <label>Lost Date</label>

                      <p>{lost_date}</p>
                    </span>

                    <span className="keyposter-lost-details">
                      <label>Lost Reward</label>

                      <p>
                        {toTitleCase(rewardcurrency)} {toTitleCase(reward)}
                      </p>
                    </span>
                  </span>

                  {latitude !== "" && longitude !== "" ? (
                    <p className="text-center lost-info-text-address-key">
                      <a
                        href={`https://maps.google.com/maps?q=${latitude},${longitude}`}
                        target="_blank"
                        rel="noreferrer"
                      >
                        {address}
                      </a>
                    </p>
                  ) : null}
                </div>
              </div>
            ) : (
              ""
            )}
          </Card>
        </div>
      </>
    );
  }
}
