import React, { useEffect, useState } from "react";
// import Buy from "../../../assets/Post_11.png";
// import SignUp from "../../../assets/Post_22.png";
// import How from "../../../assets/howitw.png";
import "./howtogetstarted.component.css";
import Navbar from "../../header/header.component.jsx";
import Button from "@material-ui/core/Button";
import axios from "axios";

function Started() {
  const [keytag, setkeytag] = useState("");
  function readCookie(name) {
    var nameEQ = name + "=";
    var ca = document.cookie.split(";");
    for (var i = 0; i < ca.length; i++) {
      var c = ca[i];
      while (c.charAt(0) == " ") c = c.substring(1, c.length);
      if (c.indexOf(nameEQ) == 0) return c.substring(nameEQ.length, c.length);
    }
    return null;
  }
  let usertoken = readCookie("token");
  const token = usertoken;
  useEffect(() => {
    axios
      .get(
        "https://dashboard.tag8.in/api/getKeyDashboardDetails/" +
          localStorage.getItem("uid"),
        {
          headers: {
            Authorization: token,
            "Content-Type": "application/json",
          },
        }
      )
      .then((res) => {
        if (res.data[0].tag_number) {
          setkeytag(res.data[0].tag_number);
        }
      })
      .catch((error) => {
        console.log(error);
      });
  });
  return (
    <div className="started" style={{ backgroundColor: "white !important" }}>
      <Navbar />
      {keytag ? (
        ""
      ) : (
        <div className="started">
          <h1 style={{ textAlign: "center" }}>How to get Started? </h1>
          <div className="started__tiles">
            <div
              className="started__tile"
              style={{ maxHeight: "400px", textAlign: "center" }}
            >
              <img
                src={"https://storage.googleapis.com/pettag/qr/Post_11.png"}
              ></img>
              <p>
                Order your <strong>Key Tag</strong>
              </p>
              <a
                className="text-white font-weight-bold"
                href="https://tag8.in/tag8-store-new/public/products/NFCKeytag.php"
              >
                BUY NOW
              </a>
            </div>
            <div
              className="started__tile"
              style={{ width: "400px", height: "400px", textAlign: "center" }}
            >
              <img
                src={"https://storage.googleapis.com/pettag/qr/Post_22.png"}
              ></img>
              <p>Sign-up for a tag8 account</p>
              <a className="text-white font-weight-bold" href="/signup">
                SIGN-UP
              </a>
            </div>
          </div>
        </div>
      )}
      <h1 style={{ padding: "50px", textAlign: "center" }}>How it works?</h1>
      <center>
        <img
          className="started__how"
          src={"https://storage.googleapis.com/pettag/qr/howitwkey.png"}
          style={{ textAlign: "center" }}
        ></img>
      </center>
    </div>
  );
}

export default Started;
